<template>
  <VueFinalModal>
    <div class="flex flex-col h-full">
      <div v-if="title" class="px-4 py-6 text-center">
        <h4
          class="text-xl font-semibold text-gray-600 uppercase"
          v-text="title"
        />
      </div>

      <div :class="modalBodyClass">
        <slot />
      </div>

      <div
        v-if="hasSlot('footer')"
        class="p-4 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
      >
        <slot name="footer" />
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { VueFinalModal } from "vue-final-modal";

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  modalBodyClass: {
    type: [Array, String],
    default: "overflow-y-scroll px-4 py-2 max-w-xl",
  },
});

const slots = useSlots();

function hasSlot(name = "default") {
  return !!slots[name] || !!slots.default[name];
}
</script>

<style lang="postcss">
.v--modal-overlay {
  @apply bg-gray-500 bg-opacity-60;
}

.v--modal {
  @apply rounded-lg shadow-lg text-base;
}
</style>
